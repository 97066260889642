.image{
width: 200px;
height: 120px;
}
.product
{
  padding: 10px;
  border-radius: 10px;

}
.button{
  display: flex;
  gap:5px;
  
}
button>button{
  font-size: 14px;
 }